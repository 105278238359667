// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbox {
    display: inline-block;
    float: right;
    margin-top: 10px;
    margin-bottom: 3px;
}
  
.toolbox button {
    margin-right: 8px;
    margin-left: 8px;
}

.toolbox-right {
    width: 300px;
    position: absolute;
    top: 85px;
    right: 20px;
    height: 200px;
    overflow: hidden;
}

.slider-field span {
    padding-left: 2px;
    text-overflow: clip;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
}

.slider-field mat-slider {
    background-color: var(--formSliderBackground);
    height: 30px;
}

.field-row {
    display: block;
    margin-bottom: 5px;
}

::ng-deep .input-text .mat-form-field-infix {
    padding-top: 5px;
    padding-bottom: 0px;
}

::ng-deep .mat-dialog-container {
    display: inline-table !important;
}

::ng-deep .mat-tab-label {
    height: 34px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/pipe/pipe-property/pipe-property.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".toolbox {\n    display: inline-block;\n    float: right;\n    margin-top: 10px;\n    margin-bottom: 3px;\n}\n  \n.toolbox button {\n    margin-right: 8px;\n    margin-left: 8px;\n}\n\n.toolbox-right {\n    width: 300px;\n    position: absolute;\n    top: 85px;\n    right: 20px;\n    height: 200px;\n    overflow: hidden;\n}\n\n.slider-field span {\n    padding-left: 2px;\n    text-overflow: clip;\n    max-width: 125px;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n.slider-field mat-slider {\n    background-color: var(--formSliderBackground);\n    height: 30px;\n}\n\n.field-row {\n    display: block;\n    margin-bottom: 5px;\n}\n\n::ng-deep .input-text .mat-form-field-infix {\n    padding-top: 5px;\n    padding-bottom: 0px;\n}\n\n::ng-deep .mat-dialog-container {\n    display: inline-table !important;\n}\n\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
