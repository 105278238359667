// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitmask-item {
    font-size: 13px;
    height: 26px !important;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/bitmask/bitmask.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".bitmask-item {\n    font-size: 13px;\n    height: 26px !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
