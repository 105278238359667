
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProjectData, ProjectDataCmdType, UploadFile } from '../../_models/project';
import { ResourceStorageService } from './resource-storage.service';
import { AlarmQuery } from '../../_models/alarm';
import { DaqQuery } from '../../_models/hmi';
import { CommanType } from '../command.service';
import { IndexedDb } from '../datadb';
import { MqttClientCustomService } from '../mqtt.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResDemoService implements ResourceStorageService {

    public onRefreshProject: () => boolean;
    indexedDB: IndexedDb;

    constructor(
        private http: HttpClient,
        indexedDB: IndexedDb,
    ) {
        this.indexedDB = indexedDB;
    }

    init(): boolean {
        return true;
    }

    getDemoProject(): Observable<any> {
        return this.http.get<any>('./assets/project.demo.fuxap', {});
    }

    getStorageProject(): Observable<any> {
        console.log('getStorageProject..');
        // this.getAppId()
        return this.indexedDB.getItem("123");
    // }
    //     this.indexedDB.getItem(this.getAppId()).subscribe(prj => {
    //         console.log('getStorageProject', prj);
    //         if (prj) {
    //             return this.indexedDB.getItem(this.getAppId());
    //         }else{
                 
    //             return new Observable((observer) => {
    //                 this.getDemoProject().subscribe(demo => {
    //                     observer.next(demo);
    //                 }, err => {
    //                     observer.error(err);
    //                 });
    //             });
    //         }
    //     }
    //     , err => {
    //         console.log('getStorageProject err', err);
    //         return new Observable((observer) => {
    //             this.getDemoProject().subscribe(demo => {
    //                 observer.next(demo);
    //             }, err => {
    //                 observer.error(err);
    //             });
    //         });
    //     }
    // );

    // return this.indexedDB.getItem(this.getAppId()); 
        
        // new Observable((observer) => {
        //     let prj = this.indexedDB.getItem(this.getAppId())
            
        //     console.log('getStorageProject',this.getAppId(), prj);
        //     if (prj) {
        //         observer.next(JSON.parse(prj));
        //     } else {
        //         // try root path
        //         this.getDemoProject().subscribe(demo => {
        //             observer.next(demo);
        //         }, err => {
        //             observer.error(err);
        //         });
        //     }

            
        // });
    }

    setServerProject(prj: ProjectData) {
        console.log('setServerProject');
        

        return this.indexedDB.setItem(this.getAppId(), JSON.stringify(prj));
    }

    setServerProjectData(cmd: ProjectDataCmdType, data: any, prj: ProjectData) {

        console.log('setServerProjectDataxxx', cmd, data);
        
        let userInfoStor = localStorage.getItem('userInfoStor');

        if(userInfoStor){
            let header = new HttpHeaders({ 'Content-Type': 'application/json' });
            let params = {username: userInfoStor,configuration:JSON.stringify(prj)};
            console.log("...........",params);
            this.http.post<any>(environment.domainBg + '/user/modifyConfigurationByUsername',params, { headers: header}).subscribe((result: any) => {
                console.log("modifyConfigurationByUsername",environment.domainBg + '/user/modifyConfigurationByUsername',result);
            });

            console.log('sxx....',userInfoStor,JSON.stringify(prj));
        }

        console.log('sxx', userInfoStor, prj);
        // this.indexedDB.getItem("userInfoStorkkk").subscribe(b=>{
        //     console.log("userInfoStor.",b);
        //     // let header = new HttpHeaders({ 'Content-Type': 'application/json' });
        //     // let params = {username: this.authService.userStor.username,configuration:this.projectData};
        //     // this.http.post(environment.domainBg + '/user/modifyConfigurationByUsername', { headers: header, params: params, responseType: 'json' }).subscribe((result: any) => {
        //     //     console.log("modifyConfigurationByUsername",result);
        //     // });
        // });

        return this.indexedDB.setItem(this.getAppId(), JSON.stringify(prj));
        
        // new Observable((observer) => {
        //     if (!prj) {
        //         observer.next();
        //     } 
        //     // else if (this.bridge) {
        //     //     let sprj = ResourceStorageService.sanitizeProject(prj);
        //     //     if (this.bridge.saveProject(sprj, false)) {
        //     //         // if (this.isDataCmdForDevice(cmd)) {
        //     //         //     let sdevice = ResourceStorageService.sanitizeDevice(data);
        //     //         //     this.bridge.deviceChange(sdevice);
        //     //         // }
        //     //         observer.next();
        //     //     } else {
        //     //         observer.error();
        //     //     }
        //     // } 
        //     else {
        //         // this.saveInLocalStorage(prj);
        //         this.indexedDB.setItem(this.getAppId(), JSON.stringify(prj));
        //         observer.next();
        //     }
        // });
        
        
        // new Observable((observer) => {
        //     observer.next('Not supported!');
        // });
    }

    uploadFile(file: any, destination?: string): Observable<UploadFile> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    getDeviceSecurity(id: string): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    setDeviceSecurity(name: string, value: string): Observable<any> {
        return new Observable((observer) => {
            observer.next('Not supported!');
        });
    }

    getAlarmsValues(): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    getAlarmsHistory(query: AlarmQuery): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    setAlarmAck(name: string): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    checkServer(): Observable<any> {
        return new Observable((observer) => {
            observer.next();
        });
    }

    getAppId() {
        return "123";
    }

    getDaqValues(query: DaqQuery): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    getTagsValues(query: string[]): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    runSysFunction(functionName: string, params?: any): Observable<any> {
        return new Observable((observer) => {
            observer.error('Not supported!');
        });
    }

    heartbeat(activity: boolean): Observable<any> {
        return new Observable(observer => {
            observer.error('Not supported!');
        });
    }

    downloadFile(fileName: string, type: CommanType): Observable<Blob> {
        return new Observable(observer => {
            observer.error('Not supported!');
        });
    }
}
