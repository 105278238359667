// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 0px 5px 0px;
    min-width: 664px;
    margin-bottom: 3px;
}

.remove {
    position: relative;
    top: 4px;
    right: 0px;
}

.item-remove {
    /* display: inline-block; */
    float: right;
    /* padding-top: 6px; */
    /* min-width: 140px; */
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-event/flex-event.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,2CAA2C;IAC3C,wBAAwB;IACxB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;AAC1B","sourcesContent":[".item {\n    display: block;\n    width: 100%;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n    padding: 0px 0px 5px 0px;\n    min-width: 664px;\n    margin-bottom: 3px;\n}\n\n.remove {\n    position: relative;\n    top: 4px;\n    right: 0px;\n}\n\n.item-remove {\n    /* display: inline-block; */\n    float: right;\n    /* padding-top: 6px; */\n    /* min-width: 140px; */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
