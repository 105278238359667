// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table {
  min-width: 900px;
  margin-top: 20px;
  height: 500px;
  display: block;
}

.data-table-cell {
  white-space: nowrap;
  max-width: 120px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
  border: 1px solid #7F7F7F;
  font-size: 12px;
  padding: 5px;
  display: inline-block;
  width: -webkit-fill-available;
}
.data-table-cell .menu {
  font-size: 18px;
  position: absolute;
  bottom: 0px;
  left: 5px;
  cursor: pointer;
}
.data-table-cell .menu mat-icon {
  font-size: 18px;
}
.data-table-cell .label {
  width: inherit;
}

.menu-item-select mat-icon {
  font-size: 18px;
  margin-right: unset !important;
}
.menu-item-select span {
  line-height: 20px;
}
.menu-item-select .unselect {
  padding-left: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/report-editor/report-item-table/report-item-table.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,cAAA;AACJ;;AAEA;EACI,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,qBAAA;EACA,6BAAA;AACJ;AACI;EACI,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AACR;AACQ;EACI,eAAA;AACZ;AAGI;EACI,cAAA;AADR;;AAOI;EACI,eAAA;EACA,8BAAA;AAJR;AAOI;EACI,iBAAA;AALR;AAQI;EACI,kBAAA;AANR","sourcesContent":[".data-table {\n    min-width: 900px;\n    margin-top: 20px;\n    height: 500px;\n    display: block;\n}\n\n.data-table-cell {\n    white-space: nowrap;\n    max-width: 120px;\n    min-height: 100px;\n    overflow: hidden;\n    position: relative;\n    border: 1px solid #7F7F7F;\n    font-size: 12px;\n    padding: 5px;\n    display: inline-block;\n    width: -webkit-fill-available;\n\n    .menu {\n        font-size: 18px;\n        position: absolute;\n        bottom: 0px;\n        left: 5px;\n        cursor: pointer;\n        \n        mat-icon {\n            font-size: 18px;\n        }\n    }\n\n    .label {\n        width: inherit;\n    }\n}\n\n.menu-item-select {\n\n    mat-icon {\n        font-size: 18px;\n        margin-right: unset !important;\n    }\n\n    span {\n        line-height: 20px;\n    }\n\n    .unselect {\n        padding-left: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
