// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-html .card-content {
    width: 100% !important;
    height: 100% !important;
}

.card-content {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: -moz-fit-content;
    width: fit-content;
}

.card-content div {
    width: 100% !important;
    height: 100% !important;
}

.card-tool {
    background-color: rgba(0,0,0,0.6);
    color:white;
    position:absolute;
    bottom: -15px;
    padding-left: 10px;
    font-size: 11px;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 194px;
    border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/cards-view/cards-view.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,iCAAiC;IACjC,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".card-html .card-content {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.card-content {\n    margin: 0 auto;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: fit-content;\n}\n\n.card-content div {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.card-tool {\n    background-color: rgba(0,0,0,0.6);\n    color:white;\n    position:absolute;\n    bottom: -15px;\n    padding-left: 10px;\n    font-size: 11px;\n    left: 50%;\n    transform: translate(-50%, -50%); \n    width: 194px;\n    border-radius: 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
