// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myslider-container {
    z-index: 1;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/ngx-nouislider/ngx-nouislider.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":[".myslider-container {\n    z-index: 1;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
