// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-body {
    display: table;
    margin: 0 auto;
}

.view-container {
    position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/view/view.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,kBAAkB;CACrB,SAAS;CACT,QAAQ;CACR,WAAW;CACX,UAAU;CACV,cAAc;AACf","sourcesContent":[".view-body {\n    display: table;\n    margin: 0 auto;\n}\n\n.view-container {\n    position: absolute;\n\tleft: 0px;\n\ttop: 0px;\n\tbottom: 0px;\n\tright: 0px;\n\toverflow: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
