import { single } from "rxjs";

declare function require(moduleName: string): any;
export const environment = {
  version: require('../../package.json').version,
  mqttPort: 8083,
  mqttServer: 'www.fskj.top',
  domainBg:"http://www.fskj.top:8061",
  // mqttServer: '192.168.1.3',
  // domainBg:"http://192.168.1.3:8061",
  production: true,
  apiEndpoint: null,
  apiPort: 1881,
  serverEnabled: false,
  type: 'client',
  single: true,
};
