// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload {
  background-color: rgba(0, 0, 0, 0.1);
}
.file-upload mat-icon {
  line-height: 30px;
}
.file-upload .clear {
  float: right;
}
.file-upload span {
  vertical-align: text-bottom;
  margin-left: 10px;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/framework/file-upload/file-upload.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;AAAI;EACI,iBAAA;AAER;AAAI;EACI,YAAA;AAER;AAAI;EACI,2BAAA;EACA,iBAAA;EACA,eAAA;AAER","sourcesContent":[".file-upload {\n    background-color: rgba(0,0,0, 0.1);\n    mat-icon {\n        line-height: 30px;\n    }\n    .clear {\n        float: right;\n    }\n    span {\n        vertical-align: text-bottom;\n        margin-left: 10px;\n        font-size: 13px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
