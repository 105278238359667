// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    /* border: 1px solid rgba(248,249,250, 1); */
}

.head {
    /* background-color: rgba(243,243,243, 1); */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    padding-top: 0;
}

.selection {
    margin-right: 20px;
    margin-bottom: -10px;
    margin-top: 2px;
    width: 220px;
}

.selection .mat-form-field-wrapper {
    padding-bottom: 15px !important;
}

.item-set {
    display: inline-block;
    float: right;
    padding-top: 13px;
    min-width: 140px;
}

.panel-color-class {
    position: relative;
    top: 30px;
}

.panel-color {
    display: inline-block;
    padding-top: 10px;
    max-width: 60px;
    /* border: 1px solid rgba(0,0,0,0.1); */
    height: 20px;
    line-height: 12px;
    margin-right: 25px;
}

.option-color {
    height: 32px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-head/flex-head.component.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;AAChD;;AAEA;IACI,4CAA4C;IAC5C,wBAAwB;IACxB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,eAAe;IACf,uCAAuC;IACvC,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".container {\n    /* border: 1px solid rgba(248,249,250, 1); */\n}\n\n.head {\n    /* background-color: rgba(243,243,243, 1); */\n    /* padding-left: 10px; */\n    /* padding-right: 10px; */\n    padding-top: 0;\n}\n\n.selection {\n    margin-right: 20px;\n    margin-bottom: -10px;\n    margin-top: 2px;\n    width: 220px;\n}\n\n.selection .mat-form-field-wrapper {\n    padding-bottom: 15px !important;\n}\n\n.item-set {\n    display: inline-block;\n    float: right;\n    padding-top: 13px;\n    min-width: 140px;\n}\n\n.panel-color-class {\n    position: relative;\n    top: 30px;\n}\n\n.panel-color {\n    display: inline-block;\n    padding-top: 10px;\n    max-width: 60px;\n    /* border: 1px solid rgba(0,0,0,0.1); */\n    height: 20px;\n    line-height: 12px;\n    margin-right: 25px;\n}\n\n.option-color {\n    height: 32px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
