// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fuxa-view-dialog {
  position: relative;
}
.fuxa-view-dialog .dialog-modal-close {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 22px;
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
}
.fuxa-view-dialog .dialog-modal-close i {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/fuxa-view/fuxa-view-dialog/fuxa-view-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;AAAJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EAEA,eAAA;AAAR;AAGQ;EACI,YAAA;AADZ","sourcesContent":["\n.fuxa-view-dialog {\n    position: relative;\n    .dialog-modal-close {\n        position: absolute;\n        top: 0px;\n        right: 0px;\n        height: 22px;\n        width: 100%;\n        color: rgba(0, 0, 0, 0.7);\n        // background-color: transparent;\n        font-size: 12px;\n        // cursor: move !important;\n    \n        i {\n            float: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
