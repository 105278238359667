// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-textarea {
    min-width: 400px;
    min-height: 200px;
    font-family: inherit;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/report-editor/report-item-text/report-item-text.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".report-textarea {\n    min-width: 400px;\n    min-height: 200px;\n    font-family: inherit;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
