// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorial-panel {
    width: 460px;
    height: 720px;
    z-index: 99999 !important;
    position: absolute;
    right: 10px;
    top: 50px;
    background-color: rgb(88 88 88);
    /* background-color: rgb(250,250,250); */
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2),
        0 2px 6px 0 rgba(0, 0, 0, 0.188235); /*1px 2px 5px 1px rgba(0,0,0,.26);*/
    border: 0px !important;
}

.tutorial-header {
    height: 36px;
    background-color: #212121;
    color: #ffffff;
    padding-left: 5px;
    cursor: move;
    line-height: 30px;
}

.tutorial-title {
    padding: 5px 10px 5px 20px;
    font-size: 16px;
}

.tutorial-close {
    font-size: 28px;
    /* float: right; */
    cursor: pointer;
    /* padding-right: 5px; */
    right: 5px;
    position: absolute;
    top: 0px;
}

.tutorial-content {
    font-size: 13px;
}

.my-expansion-panel {
    margin: 0px;
}

.header mat-panel-title {
    font-size: 16px;
    font-weight: 100;
}

::ng-deep .mat-expansion-panel-body {
    padding: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/help/tutorial/tutorial.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,+BAA+B;IAC/B,wCAAwC;IACxC;2CACuC,EAAE,mCAAmC;IAC5E,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,wBAAwB;IACxB,UAAU;IACV,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".tutorial-panel {\n    width: 460px;\n    height: 720px;\n    z-index: 99999 !important;\n    position: absolute;\n    right: 10px;\n    top: 50px;\n    background-color: rgb(88 88 88);\n    /* background-color: rgb(250,250,250); */\n    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2),\n        0 2px 6px 0 rgba(0, 0, 0, 0.188235); /*1px 2px 5px 1px rgba(0,0,0,.26);*/\n    border: 0px !important;\n}\n\n.tutorial-header {\n    height: 36px;\n    background-color: #212121;\n    color: #ffffff;\n    padding-left: 5px;\n    cursor: move;\n    line-height: 30px;\n}\n\n.tutorial-title {\n    padding: 5px 10px 5px 20px;\n    font-size: 16px;\n}\n\n.tutorial-close {\n    font-size: 28px;\n    /* float: right; */\n    cursor: pointer;\n    /* padding-right: 5px; */\n    right: 5px;\n    position: absolute;\n    top: 0px;\n}\n\n.tutorial-content {\n    font-size: 13px;\n}\n\n.my-expansion-panel {\n    margin: 0px;\n}\n\n.header mat-panel-title {\n    font-size: 16px;\n    font-weight: 100;\n}\n\n::ng-deep .mat-expansion-panel-body {\n    padding: unset !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
