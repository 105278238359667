// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  min-width: 510px;
}
.container .sel-type {
  width: 400px;
}
.container .row-input {
  width: 400px;
}
.container .schedulig-list {
  max-height: 500px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/scripts/script-scheduling/script-scheduling.component.scss"],"names":[],"mappings":"AACA;EAEI,gBAAA;AADJ;AAEI;EACI,YAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAGI;EACI,iBAAA;EACA,cAAA;AADR","sourcesContent":["\n.container {\n\n    min-width: 510px;\n    .sel-type {\n        width: 400px;\n    }\n    .row-input {\n        width: 400px;\n    }\n\n    .schedulig-list {\n        max-height: 500px;\n        overflow: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
