// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.field-row {
    display: block;
    margin-bottom: 5px;
}

.slider-field span {
    padding-left: 2px;
    text-overflow: clip;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
}

.slider-field mat-slider {
    background-color: var(--formSliderBackground);
    height: 30px;
}

::ng-deep .mat-tab-label {
    height: 34px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/slider/slider-property/slider-property.component.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,6CAA6C;IAC7C,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["\n.field-row {\n    display: block;\n    margin-bottom: 5px;\n}\n\n.slider-field span {\n    padding-left: 2px;\n    text-overflow: clip;\n    max-width: 125px;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n.slider-field mat-slider {\n    background-color: var(--formSliderBackground);\n    height: 30px;\n}\n\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
