// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: block;
  margin-bottom: 5px;
}
.container .value {
  max-width: 100px;
  margin-right: 18px;
}
.container .link {
  display: inline-block;
  vertical-align: bottom;
}
.container .link .span-link {
  text-overflow: clip;
  white-space: nowrap;
  width: 28px;
}
.container .link button {
  line-height: 28px;
  height: 28px;
  width: 28px;
  vertical-align: middle;
}
.container .input {
  margin-left: 0px;
}

::ng-deep .group-label span {
  line-height: 28px;
  height: 28px;
}

::ng-deep .option-label {
  line-height: 28px !important;
  height: 28px !important;
}
::ng-deep .option-label span {
  font-size: 13px;
}

::ng-deep .variable-input {
  font-size: 13px;
  min-width: 450px;
  vertical-align: unset !important;
  width: unset;
}

.tag-link {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 28px;
  width: 28px;
  border-radius: 2px 0px 0px 2px;
  background-color: var(--formInputBackground);
  color: var(--formInputColor);
}

.bitmask {
  vertical-align: bottom;
  margin-left: 7px;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-variable/flex-variable.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;AACJ;AAAI;EACI,gBAAA;EACA,kBAAA;AAER;AAAI;EACI,qBAAA;EACA,sBAAA;AAER;AADQ;EACI,mBAAA;EACA,mBAAA;EACA,WAAA;AAGZ;AAAQ;EACI,iBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AAEZ;AACI;EACI,gBAAA;AACR;;AAGA;EACI,iBAAA;EACA,YAAA;AAAJ;;AAGA;EAEI,4BAAA;EACA,uBAAA;AADJ;AAGI;EACI,eAAA;AADR;;AAIA;EACI,eAAA;EACA,gBAAA;EACA,gCAAA;EACA,YAAA;AADJ;;AAIA;EACI,kBAAA;EACA,WAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,8BAAA;EACA,4CAAA;EACA,4BAAA;AADJ;;AAIA;EACI,sBAAA;EACA,gBAAA;AADJ","sourcesContent":[".container {\n    display: block;\n    margin-bottom: 5px;\n    .value {\n        max-width: 100px;\n        margin-right: 18px;\n    }\n    .link {\n        display:inline-block;\n        vertical-align: bottom;\n        .span-link {\n            text-overflow:clip;\n            white-space:nowrap;\n            width: 28px\n        }\n\n        button {\n            line-height: 28px;\n            height:28px;\n            width:28px;\n            vertical-align: middle;\n        }\n    }\n    .input {\n        margin-left: 0px;\n    }\n}\n\n::ng-deep .group-label span {\n    line-height: 28px;\n    height: 28px;\n}\n\n::ng-deep .option-label  {\n\n    line-height: 28px !important;\n    height: 28px !important;\n\n    span {\n        font-size: 13px;\n    }\n}\n::ng-deep .variable-input {\n    font-size: 13px;\n    min-width: 450px;\n    vertical-align: unset !important;\n    width: unset;\n}\n\n.tag-link {\n    position:absolute; \n    bottom: 0px; \n    right: 0px; \n    height: 28px; \n    width: 28px; \n    border-radius: 2px 0px 0px 2px;\n    background-color: var(--formInputBackground);\n    color: var(--formInputColor);\n}\n\n.bitmask {\n    vertical-align: bottom; \n    margin-left: 7px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
