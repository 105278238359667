// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container {
    width: 100%;
    height: 100% !important;
    background-color:#FFFFFF
}

.work-void {
    height: 100%;
    width: 100%;
}

.work-editor {
    background-color:#FFFFFF;
    height: calc(100% - (46px));
    min-width: 800px;
}

.work-home {
    background-color:#FFFFFF;
    height: 100%;
    min-width: 800px;
}

.header {
    /* height: 40px !important;     */
}

.footer {
    height: 20px;
    position:absolute;
    bottom:0px;
}

.fab-button {
    position: absolute;
    bottom: 20px;
    left: 10px;
    color: rgba(255,255,255,1);
    background-color: rgba(68,138,255, 0);
    /* background-color: rgba(0,0,0,0.9); */
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,uBAAuB;IACvB;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,0BAA0B;IAC1B,qCAAqC;IACrC,uCAAuC;AAC3C","sourcesContent":["\n.container {\n    width: 100%;\n    height: 100% !important;\n    background-color:#FFFFFF\n}\n\n.work-void {\n    height: 100%;\n    width: 100%;\n}\n\n.work-editor {\n    background-color:#FFFFFF;\n    height: calc(100% - (46px));\n    min-width: 800px;\n}\n\n.work-home {\n    background-color:#FFFFFF;\n    height: 100%;\n    min-width: 800px;\n}\n\n.header {\n    /* height: 40px !important;     */\n}\n\n.footer {\n    height: 20px;\n    position:absolute;\n    bottom:0px;\n}\n\n.fab-button {\n    position: absolute;\n    bottom: 20px;\n    left: 10px;\n    color: rgba(255,255,255,1);\n    background-color: rgba(68,138,255, 0);\n    /* background-color: rgba(0,0,0,0.9); */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
