// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ::ng-deep .customer-table .mat-table {
    background-color: transparent !important;
    overflow: auto;
    display: block;
} */

.toolbox-column {
    z-index: 1000;
    position: absolute;
    right: 10px;
    top: 40px;
}

.toolbox-column button {
    /* margin-right: 8px; */
    margin-left: 10px;
}

.toolbox-row {
    z-index: 1000;
    position: absolute;
    left: 0px;
    bottom: -50px;
}

.toolbox-row button {
    /* margin-right: 8px; */
    margin-left: 10px;
}

.data-table-header {
    min-height: 0px;
    /* background-color: darkgray; */
}

.column-options {
    margin-left: 10px;
}

.data-table-remove-row {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -24px;
}

.data-table-cell {
    white-space: nowrap;
    max-width: 60px;
    overflow: hidden;
}

.variable-input {
    display: block; 
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/html-table/table-customizer/table-customizer.component.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":["/* ::ng-deep .customer-table .mat-table {\n    background-color: transparent !important;\n    overflow: auto;\n    display: block;\n} */\n\n.toolbox-column {\n    z-index: 1000;\n    position: absolute;\n    right: 10px;\n    top: 40px;\n}\n\n.toolbox-column button {\n    /* margin-right: 8px; */\n    margin-left: 10px;\n}\n\n.toolbox-row {\n    z-index: 1000;\n    position: absolute;\n    left: 0px;\n    bottom: -50px;\n}\n\n.toolbox-row button {\n    /* margin-right: 8px; */\n    margin-left: 10px;\n}\n\n.data-table-header {\n    min-height: 0px;\n    /* background-color: darkgray; */\n}\n\n.column-options {\n    margin-left: 10px;\n}\n\n.data-table-remove-row {\n    max-width: 40px;\n    min-width: 40px;\n    width: 40px;\n    height: 40px;\n    padding-left: 0px;\n    padding-right: 0px;\n    margin-left: -24px;\n}\n\n.data-table-cell {\n    white-space: nowrap;\n    max-width: 60px;\n    overflow: hidden;\n}\n\n.variable-input {\n    display: block; \n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
