// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-select-search-hidden {
  visibility: hidden;
}

.mat-select-search-inner {
  position: absolute;
  top: 0;
  width: calc(100% + 22px);
  border-bottom: 1px solid #cccccc;
  background-color: var(--workPanelBackground);
  z-index: 100;
}
.mat-select-search-inner.mat-select-search-inner-multiple {
  width: calc(100% + 55px);
}

::ng-deep .mat-select-search-panel {
  /* allow absolute positioning relative to outer options container */
  transform: none !important;
  max-height: 350px;
}

.mat-select-search-input {
  padding: 16px;
  padding-right: 36px;
  box-sizing: border-box;
  font-size: 15px;
  border: none;
  background-color: var(--workPanelBackground);
  color: var(--formInputColor);
}

.mat-select-search-no-entries-found {
  padding: 16px;
  color: var(--formInputColor);
}

.mat-select-search-clear {
  position: absolute;
  right: 0;
  top: 4px;
  color: var(--formInputColor);
}

::ng-deep .cdk-overlay-pane-select-search {
  /* correct offsetY so that the selected option is at the position of the select box when opening */
  margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/mat-select-search/mat-select-search.component.scss"],"names":[],"mappings":"AAKA;EACI,kBAAA;AAJJ;;AAMA;EACI,kBAAA;EACA,MAAA;EACA,wBAAA;EACA,gCAAA;EACA,4CAAA;EACA,YAAA;AAHJ;AAII;EACI,wBAAA;AAFR;;AASA;EACI,mEAAA;EACA,0BAAA;EACA,iBAAA;AANJ;;AASA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;EACA,4CAAA;EACA,4BAAA;AANJ;;AAQA;EACI,aAAA;EACA,4BAAA;AALJ;;AAOA;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,4BAAA;AAJJ;;AAMA;EACI,kGAAA;EAEA,gBAAA;AAJJ","sourcesContent":["$mat-menu-side-padding: 16px !default;\n$scrollbar-width: 10px;\n$clear-button-width: 20px;\n$multiple-check-width: 33px;\n\n.mat-select-search-hidden {\n    visibility: hidden;\n}\n.mat-select-search-inner {\n    position: absolute;\n    top: 0;\n    width: calc(100% + #{2 * 16px - $scrollbar-width});\n    border-bottom: 1px solid #cccccc;\n    background-color: var(--workPanelBackground);\n    z-index: 100;\n    &.mat-select-search-inner-multiple {\n        width: calc(\n            100% + #{2 * 16px - $scrollbar-width +\n                $multiple-check-width}\n        );\n    }\n}\n\n::ng-deep .mat-select-search-panel {\n    /* allow absolute positioning relative to outer options container */\n    transform: none !important;\n    max-height: 350px;\n}\n\n.mat-select-search-input {\n    padding: 16px;\n    padding-right: 16px + $clear-button-width;\n    box-sizing: border-box;\n    font-size: 15px;\n    border: none;\n    background-color: var(--workPanelBackground);\n    color: var(--formInputColor);\n}\n.mat-select-search-no-entries-found {\n    padding: 16px;\n    color: var(--formInputColor);\n}\n.mat-select-search-clear {\n    position: absolute;\n    right: 0;\n    top: 4px;\n    color: var(--formInputColor);\n}\n::ng-deep .cdk-overlay-pane-select-search {\n    /* correct offsetY so that the selected option is at the position of the select box when opening */\n    // margin-top: -50px;\n    margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
