// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.alarm-sample {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-error {
    display: inline-block;
    /* background-color: red;; */
    color:red;
}

::ng-deep .mat-tab-label {
    height: 34px !important;
    min-width: 120px !important;
}

.action-item {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/alarms/alarm-property/alarm-property.component.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,2CAA2C;IAC3C,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["\n.alarm-sample {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.message-error {\n    display: inline-block;\n    /* background-color: red;; */\n    color:red;\n}\n\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n    min-width: 120px !important;\n}\n\n.action-item {\n    display: block;\n    width: 100%;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\n    margin-top: 5px;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
