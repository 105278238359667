// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  min-height: 100px;
}
.container ::ng-deep .mat-tab-label {
  height: 34px !important;
  min-width: 120px !important;
}
.container input[type=time]::-webkit-calendar-picker-indicator {
  filter: var(--inputTime);
}
.container input[type=date]::-webkit-calendar-picker-indicator {
  filter: var(--inputTime);
}
.container .time {
  font-size: 16px;
  font-family: "Segoe UI Symbol", "Roboto-Regular", "Helvetica Neue", sans-serif;
}
.container .hour-time span {
  width: 40px;
}
.container .hour-time input {
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/ngx-scheduler/ngx-scheduler.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAAI;EACI,uBAAA;EACA,2BAAA;AAER;AACI;EACI,wBAAA;AACR;AAEI;EACI,wBAAA;AAAR;AAGI;EACI,eAAA;EACA,8EAAA;AADR;AAKQ;EACI,WAAA;AAHZ;AAKQ;EACI,WAAA;AAHZ","sourcesContent":[".container {\n    min-height: 100px;\n    ::ng-deep .mat-tab-label {\n        height: 34px !important;\n        min-width: 120px !important;\n    }\n\n    input[type=\"time\"]::-webkit-calendar-picker-indicator {\n        filter: var(--inputTime);\n    }\n\n    input[type=\"date\"]::-webkit-calendar-picker-indicator {\n        filter: var(--inputTime);\n    }\n\n    .time {\n        font-size: 16px;\n        font-family: \"Segoe UI Symbol\", \"Roboto-Regular\", \"Helvetica Neue\", sans-serif;\n    }\n\n    .hour-time {\n        span {\n            width: 40px;\n        }\n        input {\n            width: 40px;\n        }\n    } \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
