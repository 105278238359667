// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
::ng-deep .mat-tab-label {
    height: 34px !important;
    padding: 0;
}

.tabs-container {
    min-height: 500px;
    min-width: 620px;
}

.tabs-container .tab-system {
    min-width: 320px;
}

.tabs-container .tab-smtp {
    padding-right: 10px;
}

.tabs-container .tab-daq {
    padding-right: 10px;
}

.tabs-container .tab-alarms {
    padding-right: 10px;
}

.input-row {
    width: 100%;
}

.system-input {
    width: 320px;
}`, "",{"version":3,"sources":["webpack://./src/app/editor/app-settings/app-settings.component.css"],"names":[],"mappings":";AACA;IACI,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n    padding: 0;\n}\n\n.tabs-container {\n    min-height: 500px;\n    min-width: 620px;\n}\n\n.tabs-container .tab-system {\n    min-width: 320px;\n}\n\n.tabs-container .tab-smtp {\n    padding-right: 10px;\n}\n\n.tabs-container .tab-daq {\n    padding-right: 10px;\n}\n\n.tabs-container .tab-alarms {\n    padding-right: 10px;\n}\n\n.input-row {\n    width: 100%;\n}\n\n.system-input {\n    width: 320px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
