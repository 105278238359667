// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-variable-mapping {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-bottom: 3px;
    padding-top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-variable-map/flex-variable-map.component.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".flex-variable-mapping {\n    border-bottom: 1px solid rgba(0, 0, 0, 0.08);\n    padding-bottom: 3px;\n    padding-top: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
