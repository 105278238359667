import { GaugesManager } from '../gauges/gauges.component';
import { HmiService, ScriptSetView } from '../_services/hmi.service';
import { Variable } from './hmi';

type SendData = (params:any) => void ;
type IntervalDeal = () => void ;


export class MediaInfo {
    id:number;
    clientid:string;
    state:boolean;
    name:string = "device";
    ver:string = "0";
    sVer:string = "";
    rssi:number = 0;
    loraCh:number = 0;


    constructor(id:number,clientid:string,state:boolean,name:string) {
        this.id = id;
        this.clientid = clientid;
        this.state = state;
        this.name = name;
    }

    parseJson(map:any){
        this.ver = map["ver"];
        this.sVer = map["sVer"];
        this.name = map["name"];

        if(map["loraCh"]!==undefined){this.loraCh = map["loraCh"];}
        if(map["rssi"]!==undefined){this.rssi = map["rssi"];}

    }
}

export class DataVal {
    index:number = 0;
    open:number = 0;
    delay:number = 0;
    en:number = 0;
    dt:number = 0;

    sign:boolean = false;
    ifc:number = 0;
    formula:number = 0;
    precision:number = 2;
    unit:number = 0;
    limit:number = 0;
    bias:number = 0;
    limitM:number = 0;
    type:number = 0;
    valL:number = 0;
    valH:number = 0;

    listUnit:string[] = ["无", "m","mm","℃","g","kg","ppm","mg/L","%","NTU","mv","%RH","KPa","g/m3"];

    constructor() {
        
    }

    parseJson(regAddrType:number,b:any){
        this.index = b["index"];

        this.delay = b["delay"];
        this.en = b["en"];

        if(regAddrType === 1){
            this.open  = b["open"];
            this.dt  = b["dt"]??0;
        }else if(regAddrType === 2){
            this.ifc  =b["ifc"];
            this.formula = b["formula"];
            this.precision = b["precision"];
            this.unit = b["unit"] ;
            this.limit = b["limit"];
            this.limitM = b["limitM"];
            this.bias = b["bias"];
            this.type = b["type"]??0;
            this.valL = b["valL"]??4;
            this.valH = b["valH"]??16;
        }
    }
}

export enum RegAddrType {
  bitInput = 0,
  bitOutput = 1,
  dataSensor = 2,
  deviceInfo = 3,
  bitOutput16 = 4,
  addr = 5,
  max
}

export class RegAddrSpace {
    // 0:input 1:output 2:sensor 3:deviceinfo 
    regAddrType:number=6;
    start:number=0;
    sum:number=0;
    warn:number=0;
    listName: string[]=[];
    listVal: number[]=[];
    listWarn: number[]=[];
    listIOmap: number[]=[];
    listDataVal:DataVal[]=[];

    constructor() {
        
    }

    parseJson(map:any){
        this.regAddrType = map["regAddrType"]??6;
        this.start = map["start"];
        this.sum = map["sum"];
        this.warn = map["warn"];

        let listN = map["listName"];
        this.listName = [];

        for (let index = 0; index < listN.length; index++) {
            this.listName.push(listN[index]);
        }
        
        if(this.listVal.length === 0){
            for (let index = 0; index < this.sum; index++) {
                this.listVal.push(0);
                this.listWarn.push(0);
                if(this.regAddrType === 2){this.listIOmap.push(1);}
                else {this.listIOmap.push(index+1);}
            }
        }

        if(map["listDataVal"]!=undefined){
            let listDataValTemp = map["listDataVal"];
            this.listDataVal = [];
            for (let index = 0; index < listDataValTemp.length; index++) {
                let dataVal:DataVal = new DataVal();
                dataVal.parseJson(this.regAddrType,listDataValTemp[index]);
                this.listDataVal.push(dataVal);
            }
        }

        if(map["listIOmap"]!==undefined){
            let listIOmapTemp = map["listIOmap"];
            for (let index = 0; index < listIOmapTemp.length; index++) {
                this.listIOmap = listIOmapTemp[index];
            }
        }

    }

    getName(index:number){return index < this.listName.length?  this.listName[index]:"...";}
    getVal(index:number) {return (index < this.listVal.length?this.listVal[index]:0xffff);}
    getWarn(index:number) {return (index < this.listWarn.length?this.listWarn[index]:0xffff);}
    isFloat():boolean {return ((this.warn>>8)&0xf)==1;}


    get3Level():number{
        if(this.listVal.length == 3){
          if(this.listVal[2]>0){return 3;}
          else if((this.listVal[1]>0)){return 2;}
          else if(this.listVal[0]>0){return 1;}
          else {return 0;}
        }
        return 0;
      }
      get3LevelStr():string{
        switch(this.get3Level()){
          case 0:return "关闭";
          case 1:return "33%";
          case 2:return "66%";
          case 3:return "99%";
        }
        return "关闭";
      }

      getSensorVal(index:number){
        if(this.regAddrType == 2){
          let temp = (index < this.listVal.length?this.listVal[index]:0xffff)/1.0;
    
          if(index < this.listDataVal.length){
            if(this.isFloat()){
              temp = temp/1000.0;
            }else{
              temp = temp/Math.pow(10, this.listDataVal[index].precision);
            }
            
            
            return temp;
          }
        }
        return 0;
      } 

      getSensorValStr(index:number):string{
        let temp =this.getSensorVal(index);
        if(this.listDataVal[index].precision == 0){
          return temp.toFixed(0);
        }else if(this.listDataVal[index].precision == 1){
          return temp.toFixed(1);
        }else if(this.listDataVal[index].precision == 3){
          return temp.toFixed(3);
        }
        
        return temp.toFixed(2);
      }
}

export class Slave {
    addr: number = 0xffff;
    state:boolean = false;
    name:string = "slave";
    type:number = 0;
    warn:number = 0;

    ver:number = 0;
    listRegAddrSpace: RegAddrSpace[];
    constructor() {
        
    }

    parseJson(map:any){
        this.addr = map["addr"];
        this.type = map["type"]??0;
        this.name = map["name"];
        if(map["listRegAddrSpace"] !== undefined){
            let list = map["listRegAddrSpace"];
            this.listRegAddrSpace = [];
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                let regAddrSpace:RegAddrSpace = new RegAddrSpace();
                regAddrSpace.parseJson(element);
                this.listRegAddrSpace.push(regAddrSpace);
            }
        }
    }
}



// export class MasterDeviceInfo {
    

//     sendData:SendData;
//     intervalDeal:IntervalDeal;

//     private hmiService: HmiService;
//     private result: Variable[] = [];
//     constructor(id:number, clientid:string,state:boolean,name:string,sendData:SendData,intervalDeal:IntervalDeal,public gaugesManager: GaugesManager,private _hmiService: HmiService,) {
//         this.mediaInfo = new MediaInfo(id,clientid,state,name);
//         this.sendData = sendData;
//         this.intervalDeal = intervalDeal;
//         this.hmiService = _hmiService;
//         this.result = this.gaugesManager.getMappedGaugesSignals(true);
//     }

    

    
// }



