// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*@import "~xgplayer/dist/index.min.css";*/
.webcam-player-container {
    min-width: 600px;
    /*min-height: 30px;*/
}
`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/webcam-player/webcam-player.component.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":["/*@import \"~xgplayer/dist/index.min.css\";*/\n.webcam-player-container {\n    min-width: 600px;\n    /*min-height: 30px;*/\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
