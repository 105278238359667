// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alarms {
  min-width: 600px;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}

.alarms-filter {
  width: 100%;
  margin-top: 5px;
}
.alarms-filter mat-selection-list {
  overflow: auto;
  height: 300px;
}
.alarms-filter mat-list-option ::ng-deep .mat-list-text {
  display: block;
}
.alarms-filter .alarm-name {
  width: 50%;
  display: inline-block;
}
.alarms-filter .alarm-tag {
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
}
.alarms-filter .list-header {
  display: block;
}
.alarms-filter .list-header span {
  margin-bottom: 2px;
}
.alarms-filter .list-header mat-checkbox {
  position: absolute;
  right: 16px;
  top: -5px;
}
.alarms-filter .alarm-list {
  background: var(--formInputBackground);
}
.alarms-filter .alarm-item {
  font-size: 14px;
  height: 34px;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/report-editor/report-item-alarms/report-item-alarms.component.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,WAAA;EACA,eAAA;AAAJ;AAEI;EACI,cAAA;EACA,aAAA;AAAR;AAGI;EACI,cAAA;AADR;AAII;EACI,UAAA;EACA,qBAAA;AAFR;AAKI;EACI,qBAAA;EACA,+BAAA;AAHR;AAMI;EACI,cAAA;AAJR;AAKQ;EACI,kBAAA;AAHZ;AAMQ;EACI,kBAAA;EACA,WAAA;EACA,SAAA;AAJZ;AAQI;EACI,sCAAA;AANR;AASI;EACI,eAAA;EACA,YAAA;AAPR","sourcesContent":["\n.alarms {\n    min-width: 600px;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    display: block;\n}\n\n.alarms-filter {\n    width: 100%;\n    margin-top: 5px;\n\n    mat-selection-list {\n        overflow: auto;\n        height: 300px;\n    }\n\n    mat-list-option ::ng-deep .mat-list-text {\n        display: block;\n    }\n\n    .alarm-name {\n        width: 50%;\n        display: inline-block;\n    }\n\n    .alarm-tag {\n        display: inline-block;\n        color: rgba(255, 255, 255, 0.6);\n    }\n\n    .list-header {\n        display: block;\n        span {\n            margin-bottom: 2px;\n        }\n\n        mat-checkbox {\n            position: absolute;\n            right: 16px;\n            top: -5px;\n        }\n    }\n\n    .alarm-list {\n        background: var(--formInputBackground);\n    }\n\n    .alarm-item {\n        font-size: 14px;\n        height: 34px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
