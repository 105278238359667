// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  min-height: 400px;
  min-width: 800px;
  margin-top: 10px;
}

.tag-Id-ref {
  display: block;
}
.tag-Id-ref flex-variable {
  display: inline-block;
}
.tag-Id-ref span {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/app/editor/tags-ids-config/tags-ids-config.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AACA;EACI,cAAA;AAEJ;AADI;EACI,qBAAA;AAGR;AADI;EACI,gBAAA;EACA,iBAAA;EACA,qBAAA;AAGR","sourcesContent":[".content {\n    min-height: 400px;\n    min-width: 800px;\n    margin-top: 10px;\n}\n.tag-Id-ref {\n    display: block;\n    flex-variable {\n        display: inline-block;\n    }\n    span {\n        margin-left: 5px;\n        margin-right: 5px;\n        display: inline-block;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
