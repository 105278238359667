// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-selector-list {
  position: absolute;
  top: 35px;
  bottom: 0px;
  overflow: auto;
  margin-top: 10px;
  right: 0px;
  left: 5px;
}

.svg-selector-item {
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
  padding-left: 5px;
  display: block;
}
.svg-selector-item .name {
  display: inline-block;
  width: 170px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom;
}
.svg-selector-item .id {
  display: inline-block;
  margin-left: 10px;
  color: darkgray;
  width: 150px;
}
.svg-selector-item .edit {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.svg-selector-item .edit mat-icon {
  font-size: 16px;
}

.svg-selector-item:hover {
  background-color: rgba(156, 156, 156, 0.2117647059);
}

.svg-selector-active {
  background-color: rgb(48, 89, 175);
}`, "",{"version":3,"sources":["webpack://./src/app/editor/svg-selector/svg-selector.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,UAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAAI;EACI,qBAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AAER;AACI;EACI,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AACR;AACI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACR;AAAQ;EACI,eAAA;AAEZ;;AAGA;EACI,mDAAA;AAAJ;;AAGA;EACI,kCAAA;AAAJ","sourcesContent":[".svg-selector-list {\n    position: absolute;\n    top: 35px;\n    bottom: 0px;\n    overflow: auto;\n    margin-top: 10px;\n    right: 0px;\n    left: 5px;\n}\n\n.svg-selector-item {\n    font-size: 12px;\n    line-height: 24px;\n    cursor: pointer;\n    padding-left: 5px;\n    display: block;\n    .name {\n        display: inline-block;\n        width: 170px;\n        font-size: 13px;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n        overflow: hidden;\n        vertical-align: bottom; \n    }\n\n    .id {\n        display: inline-block;\n        margin-left: 10px;\n        color:darkgray;\n        width: 150px;\n    }\n    .edit {\n        width: 22px;\n        height: 22px;\n        line-height: 22px;\n        mat-icon {\n            font-size: 16px;\n        }\n    }\n}\n\n.svg-selector-item:hover {\n    background-color: #9c9c9c36;\n}\n\n.svg-selector-active {\n    background-color: rgba(48,89,175,1);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
