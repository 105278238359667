// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
    min-width: 600px;
    height: 400px; 
    font-size: 16px !important; 
    padding-top: 0px !important;
}

.list-header {
    display: block;
    height: 20px !important;
}

.list-header span {
    text-overflow: ellipsis;
    overflow: hidden;
    color: gray;
    font-size: 12px;
}

.list-item {
    display: block;
    font-size: 14px;
    height: 40px !important;
    /* cursor: pointer; */
    overflow: hidden;
}

.list-item span {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list-item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/editor/plugins/plugins.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,uBAAuB;IACvB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".list {\n    min-width: 600px;\n    height: 400px; \n    font-size: 16px !important; \n    padding-top: 0px !important;\n}\n\n.list-header {\n    display: block;\n    height: 20px !important;\n}\n\n.list-header span {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    color: gray;\n    font-size: 12px;\n}\n\n.list-item {\n    display: block;\n    font-size: 14px;\n    height: 40px !important;\n    /* cursor: pointer; */\n    overflow: hidden;\n}\n\n.list-item span {\n    width: 160px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.list-item-text {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
