// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-expansion-panel-body {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.my-expansion-panel {
  width: 356px;
  display: block;
  margin-top: 10px;
}
.my-expansion-panel mat-expansion-panel {
  box-shadow: none !important;
  background-color: var(--formExtBackground);
  border-radius: 2px;
}

.multiline {
  overflow: auto;
  max-height: 300px;
}

.address {
  width: 350px;
}

.adpu {
  width: 120px;
}

.header {
  font-size: 13px;
  padding-left: 5px;
  padding-right: 15px;
}

.expand-panel {
  display: block;
  margin: 5px 10px 10px 10px;
}
.expand-panel .my-form-field {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/device/device-property/device-property.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,6BAAA;AACJ;;AAEA;EACI,YAAA;EACA,cAAA;EACA,gBACE;AAAN;AACI;EACI,2BAAA;EACA,0CAAA;EACA,kBAAA;AACR;;AAGA;EACI,cAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,YAAA;AAAJ;;AAGA;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,cAAA;EACA,0BAAA;AAAJ;AAEI;EACI,eAAA;AAAR","sourcesContent":["::ng-deep .mat-expansion-panel-body {\n    padding-left: 5px !important;\n    padding-right: 5px !important;\n}\n\n.my-expansion-panel {\n    width: 356px; \n    display: block;\n    margin-top\n    : 10px;\n    mat-expansion-panel {\n        box-shadow: none !important;\n        background-color:var(--formExtBackground);\n        border-radius: 2px;\n    }\n}\n\n.multiline {\n    overflow: auto;\n    max-height: 300px;\n}\n\n.address {\n    width: 350px;\n}\n\n.adpu {\n    width: 120px;\n}\n\n.header {\n    font-size: 13px;\n    padding-left: 5px;\n    padding-right: 15px;\n}\n\n.expand-panel {\n    display: block;\n    margin: 5px 10px 10px 10px;\n\n    .my-form-field {\n        margin-top: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
