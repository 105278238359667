// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lib-image-panel {
    max-width: 900px;
}

.lib-image-item {
    display: inline-block;
    margin: 5px 5px 5px 5px;
    padding: 2px 2px 2px 2px;
}

.lib-image-item img:hover {
    background-color: rgba(182, 182, 182, 0.3);
}

.lib-image-item img {
    min-width: 100px;
    width: 100px;
    object-fit: cover;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/resources/lib-images/lib-images.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".lib-image-panel {\n    max-width: 900px;\n}\n\n.lib-image-item {\n    display: inline-block;\n    margin: 5px 5px 5px 5px;\n    padding: 2px 2px 2px 2px;\n}\n\n.lib-image-item img:hover {\n    background-color: rgba(182, 182, 182, 0.3);\n}\n\n.lib-image-item img {\n    min-width: 100px;\n    width: 100px;\n    object-fit: cover;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
