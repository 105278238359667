// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lab-body {
    display: table;
    margin: 0 auto;
}
.box_shadow {
    box-shadow: 0px 2px 6px -1px #888888;
    filter: 
    drop-shadow( 3px 3px 2px rgba(0,0,0,.7) );
}

.fab-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/lab/lab.component.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,cAAc;AAClB;AACA;IAGI,oCAAoC;IAG9B;6CACmC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":["\n.lab-body {\n    display: table;\n    margin: 0 auto;\n}\n.box_shadow {\n    -webkit-box-shadow: 0px 2px 6px -1px #888888;\n    -moz-box-shadow: 0px 2px 6px -1px #888888;\n    box-shadow: 0px 2px 6px -1px #888888;\n    -webkit-filter: \n    drop-shadow( 3px 3px 2px rgba(0,0,0,.7) );\n          filter: \n    drop-shadow( 3px 3px 2px rgba(0,0,0,.7) );\n}\n\n.fab-btn {\n    position: absolute;\n    right: 10px;\n    bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
