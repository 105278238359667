// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbox {
    float: right;
    line-height: 44px;
}

.toolbox button {
    /* margin-right: 8px; */
    margin-left: 10px;
}

::ng-deep .input-text .mat-form-field-infix {
    padding-top: 5px;
    padding-bottom: 0px;
}

::ng-deep .mat-dialog-container {
    display: inline-table !important;
}

::ng-deep .mat-tab-label {
  height: 34px !important;
}

.mat-tab-container {
  min-height:300px;
  height:60vmin;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 15px;
}

.mat-tab-container > div {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/gauge-property.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".toolbox {\n    float: right;\n    line-height: 44px;\n}\n\n.toolbox button {\n    /* margin-right: 8px; */\n    margin-left: 10px;\n}\n\n::ng-deep .input-text .mat-form-field-infix {\n    padding-top: 5px;\n    padding-bottom: 0px;\n}\n\n::ng-deep .mat-dialog-container {\n    display: inline-table !important;\n}\n\n::ng-deep .mat-tab-label {\n  height: 34px !important;\n}\n\n.mat-tab-container {\n  min-height:300px;\n  height:60vmin;\n  overflow-y: auto;\n  overflow-x: hidden;\n  padding-top: 15px;\n}\n\n.mat-tab-container > div {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
