// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.mygauge-container {
    z-index: 1;
    position: relative;
    width: 100%; 
    height:100%;
    padding: 20;
}

.mygauge-canvas {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mygauge-value {
    text-align: center; 
    font-size: 18px; 
    font-weight: bold;
    color: black; 
    font-family: "Segoe UI Symbol", "Roboto-Regular", "Helvetica Neue", sans-serif !important;
    position: absolute; 
    top: 5px; 
    left: 0; 
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/ngx-gauge/ngx-gauge.component.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,WAAW;AACf;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,SAAS;IAET,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,yFAAyF;IACzF,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,QAAQ;AACZ","sourcesContent":["\n.mygauge-container {\n    z-index: 1;\n    position: relative;\n    width: 100%; \n    height:100%;\n    padding: 20;\n}\n\n.mygauge-canvas {\n    margin: 0;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -ms-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n}\n\n.mygauge-value {\n    text-align: center; \n    font-size: 18px; \n    font-weight: bold;\n    color: black; \n    font-family: \"Segoe UI Symbol\", \"Roboto-Regular\", \"Helvetica Neue\", sans-serif !important;\n    position: absolute; \n    top: 5px; \n    left: 0; \n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
