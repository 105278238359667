// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-toolbar {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 9999;
}

.small-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.small-icon-button .mat-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.small-icon-button .material-icons {
  font-size: 20px;
}

.reload-active {
  animation: spin 1.2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
    transform-origin: center center;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: center center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/html-graph/graph-bar/graph-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,QAAA;EACA,aAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AACR;AAEI;EACI,eAAA;AAAR;;AAIA;EAEI,oCAAA;AADJ;AAaA;EACI;IACI,uBAAA;IACA,+BAAA;EAFN;EAIE;IACI,yBAAA;IACA,+BAAA;EAFN;AACF","sourcesContent":[".graph-toolbar {\n    position: fixed;\n    right: 0px;\n    top: 0px;\n    z-index: 9999;\n}\n\n.small-icon-button {\n    width: 24px;\n    height: 24px;\n    line-height: 24px;\n\n    .mat-icon {\n        width: 20px;\n        height: 20px;\n        line-height: 20px;\n    }\n\n    .material-icons {\n        font-size: 20px;\n    }\n}\n\n.reload-active {\n    -webkit-animation: spin 1.2s linear infinite;\n    animation: spin 1.2s linear infinite;\n}\n\n@-webkit-keyframes spin {\n    0% { \n        -webkit-transform: rotate(0deg);\n    }\n    100% { \n        -webkit-transform: rotate(360deg);\n    }\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n        transform-origin: center center;\n    }\n    100% {\n        transform: rotate(360deg);\n        transform-origin: center center;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
