// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webcam-player-dialog .dialog-modal-close {
  top: 0px;
  right: 0px;
  height: 22px;
  width: 100%;
  background-color: transparent;
  font-size: 12px;
  cursor: move !important;
}
.webcam-player-dialog .dialog-modal-close i {
  float: right;
}
.webcam-player-dialog app-webcam-player {
  padding-top: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/webcam-player/webcam-player-dialog/webcam-player-dialog.component.scss"],"names":[],"mappings":"AAEI;EAEI,QAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EAEA,6BAAA;EACA,eAAA;EACA,uBAAA;AAHR;AAIQ;EACI,YAAA;AAFZ;AAKI;EACI,iBAAA;AAHR","sourcesContent":[".webcam-player-dialog {\n    //position: relative;\n    .dialog-modal-close {\n        //position: absolute;\n        top: 0px;\n        right: 0px;\n        height: 22px;\n        width: 100%;\n        //color: rgba(0, 0, 0, 0.7);\n        background-color: transparent;\n        font-size: 12px;\n        cursor: move !important;\n        i {\n            float: right;\n        }\n    }\n    app-webcam-player {\n        padding-top: 22px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
