// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-property-split2:after {
    display: table;
    clear: both;
}

.svg-property-split2 div {
    display: inline-block;
}

.svg-property {
    color: rgba(255, 255, 255, 0.75);

}

.svg-property span {
    display: block;
    font-size: 10px;
    margin: 0px 5px 0px 10px;
}

.svg-property input {
    display: block;
    margin: 0px 10px 12px 10px;
    border: unset;
    background-color: inherit;
    color: var(--toolboxFlyColor);
    border-bottom: 1px solid var(--toolboxFlyColor);
}

.mat-button {
    min-width: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-base/gauge-base.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;;AAEpC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,aAAa;IACb,yBAAyB;IACzB,6BAA6B;IAC7B,+CAA+C;AACnD;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".svg-property-split2:after {\n    display: table;\n    clear: both;\n}\n\n.svg-property-split2 div {\n    display: inline-block;\n}\n\n.svg-property {\n    color: rgba(255, 255, 255, 0.75);\n\n}\n\n.svg-property span {\n    display: block;\n    font-size: 10px;\n    margin: 0px 5px 0px 10px;\n}\n\n.svg-property input {\n    display: block;\n    margin: 0px 10px 12px 10px;\n    border: unset;\n    background-color: inherit;\n    color: var(--toolboxFlyColor);\n    border-bottom: 1px solid var(--toolboxFlyColor);\n}\n\n.mat-button {\n    min-width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
