// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    /* min-width: 400px; */
    /* margin-top: 20px; */
    /* min-height: 250px; */
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/device/device-map/device-webapi-property-dialog/device-webapi-property-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":[".content {\n    /* min-width: 400px; */\n    /* margin-top: 20px; */\n    /* min-height: 250px; */\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
