// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 620px;
  position: relative;
}
.container .input-radius input {
  width: 80px;
}
.container .input-font-size input {
  width: 80px;
}
.container .input-font mat-select {
  width: 160px;
}

.field-row {
  display: block;
  margin-bottom: 5px;
}

.slider-field span {
  padding-left: 2px;
  text-overflow: clip;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
}

.slider-field mat-slider {
  background-color: var(--formSliderBackground);
  height: 29px;
}

.slider-small > .mat-slider-horizontal {
  min-width: 80px;
}

::ng-deep .mat-tab-label {
  height: 34px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/html-switch/html-switch-property/html-switch-property.component.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,kBAAA;AAAJ;AAKQ;EACI,WAAA;AAHZ;AAMI;EACI,WAAA;AAJR;AAOI;EACI,YAAA;AALR;;AASA;EACI,cAAA;EACA,kBAAA;AANJ;;AASA;EACI,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AANJ;;AASA;EACI,6CAAA;EACA,YAAA;AANJ;;AASA;EACI,eAAA;AANJ;;AASA;EACI,uBAAA;AANJ","sourcesContent":["\n.container {\n    width: 620px;\n    position: relative;\n\n    .input-radius {\n        // margin-left: 60px;\n\n        input {\n            width: 80px;\n        }\n    }\n    .input-font-size input {\n        width: 80px;\n    }\n\n    .input-font mat-select {\n        width: 160px;\n    }\n}\n\n.field-row {\n    display: block;\n    margin-bottom: 5px;\n}\n\n.slider-field span {\n    padding-left: 2px;\n    text-overflow: clip;\n    width: 150px;\n    white-space: nowrap;\n    overflow: hidden;\n}\n\n.slider-field mat-slider {\n    background-color: var(--formSliderBackground);\n    height: 29px;\n}\n\n.slider-small > .mat-slider-horizontal {\n    min-width: 80px;\n}\n\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
