// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    display: block;
    /* min-height: 100px; */
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 0px 0px 5px 0px;
    margin-bottom: 3px;
}

.item-head {
    display: block;
}

.remove {
    position: relative;
    top: 4px;
    right: 0px;
}

.item-remove {
    float: right;
    /* padding-top: 6px; */
    /* min-width: 140px; */
}

.action-content {
    display: block;
    padding-top: 2px;
    margin-left: 30px;
}

.item-range {
    display: inline-block;
    min-width: 240px;
    width: 240px;
}

.action-params {
    display: inline-block;
    padding-top: 5px;
}

.input-slider {
    display: inline;
    /* max-width: 160px; */
}
::ng-deep .input-slider span {
    font-size: 14px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-action/flex-action.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,WAAW;IACX,wCAAwC;IACxC,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B;AACA;IACI,0BAA0B;AAC9B","sourcesContent":[".item {\n    display: block;\n    /* min-height: 100px; */\n    width: 100%;\n    border-bottom: 1px solid rgba(0,0,0,0.1);\n    padding: 0px 0px 5px 0px;\n    margin-bottom: 3px;\n}\n\n.item-head {\n    display: block;\n}\n\n.remove {\n    position: relative;\n    top: 4px;\n    right: 0px;\n}\n\n.item-remove {\n    float: right;\n    /* padding-top: 6px; */\n    /* min-width: 140px; */\n}\n\n.action-content {\n    display: block;\n    padding-top: 2px;\n    margin-left: 30px;\n}\n\n.item-range {\n    display: inline-block;\n    min-width: 240px;\n    width: 240px;\n}\n\n.action-params {\n    display: inline-block;\n    padding-top: 5px;\n}\n\n.input-slider {\n    display: inline;\n    /* max-width: 160px; */\n}\n::ng-deep .input-slider span {\n    font-size: 14px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
