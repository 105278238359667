// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.device-input {
  display: inline-block;
  width: 100%;
}
.device-input input {
  padding-left: 30px;
  width: calc(100% - 36px);
}
.device-input button {
  position: absolute;
  left: 0px;
  line-height: 22px;
  height: 28px;
  width: 28px;
  vertical-align: middle;
}

::ng-deep .device-group-label span {
  line-height: 28px;
  height: 28px;
}

::ng-deep .device-option-label {
  line-height: 28px !important;
  height: 28px !important;
}
::ng-deep .device-option-label span {
  font-size: 13px;
}

::ng-deep .device-variable-input {
  font-size: 13px;
  vertical-align: unset !important;
  width: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/gauge-property/flex-device-tag/flex-device-tag.component.scss"],"names":[],"mappings":"AACA;EACI,qBAAA;EACA,WAAA;AAAJ;AACI;EACI,kBAAA;EACA,wBAAA;AACR;AAEI;EACI,kBAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AAAR;;AAIA;EACI,iBAAA;EACA,YAAA;AADJ;;AAIA;EAEI,4BAAA;EACA,uBAAA;AAFJ;AAII;EACI,eAAA;AAFR;;AAKA;EACI,eAAA;EACA,gCAAA;EACA,YAAA;AAFJ","sourcesContent":["\n.device-input {\n    display: inline-block;\n    width: 100%;\n    input {\n        padding-left: 30px;\n        width: calc(100% - 36px);\n\n    }\n    button {\n        position: absolute;\n        left: 0px;\n        line-height: 22px;\n        height:28px;\n        width:28px;\n        vertical-align: middle;\n    }\n}\n\n::ng-deep .device-group-label span {\n    line-height: 28px;\n    height: 28px;\n}\n\n::ng-deep .device-option-label  {\n\n    line-height: 28px !important;\n    height: 28px !important;\n\n    span {\n        font-size: 13px;\n    }\n}\n::ng-deep .device-variable-input {\n    font-size: 13px;\n    vertical-align: unset !important;\n    width: unset;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
