// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    display: block;
    font-size: 12px;
}

.message-error {
    width: 100%;
    /* background-color: red;; */
    color: red;
    padding-bottom: 20px;
    font-size: 13px;
}

.show-password {
    bottom: 2px;
    right: 0px;
    position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,4BAA4B;IAC5B,UAAU;IACV,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".error {\n    display: block;\n    font-size: 12px;\n}\n\n.message-error {\n    width: 100%;\n    /* background-color: red;; */\n    color: red;\n    padding-bottom: 20px;\n    font-size: 13px;\n}\n\n.show-password {\n    bottom: 2px;\n    right: 0px;\n    position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
