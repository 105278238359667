// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    background-color: white;
}

.custom-datapicker {
    display: flex;
}

.action-panel {
    float: right;
    padding-right: 20px;
    margin-bottom: 8px;
}

.action-panel .btn-cancel {
    background-color: white;
    color: black;
    margin-right: 10px;
}

::ng-deep .light-dialog-container .mat-dialog-container {
    background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/daterange-dialog/daterange-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".container {\n    background-color: white;\n}\n\n.custom-datapicker {\n    display: flex;\n}\n\n.action-panel {\n    float: right;\n    padding-right: 20px;\n    margin-bottom: 8px;\n}\n\n.action-panel .btn-cancel {\n    background-color: white;\n    color: black;\n    margin-right: 10px;\n}\n\n::ng-deep .light-dialog-container .mat-dialog-container {\n    background-color: white !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
