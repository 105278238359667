// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-container {
    width: 200px;
}

.range-type {
    display: inline-block;
}

.range-type-switch {
    float: right;
}

.range-type-switch button {
    margin-top: 11px;
    line-height: 30px;
    height:30px; 
    width:30px;
}

.boolean-selection {
    width: 157px;
}`, "",{"version":3,"sources":["webpack://./src/app/gui-helpers/range-number/range-number.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".range-container {\n    width: 200px;\n}\n\n.range-type {\n    display: inline-block;\n}\n\n.range-type-switch {\n    float: right;\n}\n\n.range-type-switch button {\n    margin-top: 11px;\n    line-height: 30px;\n    height:30px; \n    width:30px;\n}\n\n.boolean-selection {\n    width: 157px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
