// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-error {
    display: inline-block;
    /* background-color: red;; */
    color:red;
}`, "",{"version":3,"sources":["webpack://./src/app/notifications/notification-property/notification-property.component.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,SAAS;AACb","sourcesContent":[".message-error {\n    display: inline-block;\n    /* background-color: red;; */\n    color:red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
