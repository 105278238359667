// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-selection {
    position: absolute;
    top: 35px;
    bottom: 0px;
    overflow: auto;
    width:calc(100% - 12px);
}

.section-item {
    width: 100%;
}

.section-item-block input, span, mat-select {
    width:calc(100% - 10px);
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/panel/panel-property/panel-property.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".panel-selection {\n    position: absolute;\n    top: 35px;\n    bottom: 0px;\n    overflow: auto;\n    width:calc(100% - 12px);\n}\n\n.section-item {\n    width: 100%;\n}\n\n.section-item-block input, span, mat-select {\n    width:calc(100% - 10px);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
