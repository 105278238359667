// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-selection {
    position: absolute;
    top: 35px;
    bottom: 0px;
    overflow: auto;
    width: 340px;
}

.section-item {
    width: 100%;
}

.section-item-block mat-select, span {
    width:calc(100% - 15px);
}

.section-inline-number {
    display: inline-block;
    width: 60px;
}

.section-inline-number input {
    width: inherit;
    text-align: center;
}

.section-inline-number span {
    width: 65px;
    text-align: left;
}

.section-inline-toggle {
    display: inline-block;
    width: 50px;
    text-align: center;
}

.section-inline-toggle span {
    width: inherit;
}

.section-inline-toggle mat-slide-toggle {
    width: inherit;
    padding-left: 10px;
}

.section-inline-toggle-ext span {
    width: 85px;
}

.section-inline-toggle-ext mat-slide-toggle {
    padding-left: 20px;
}

.section-inline-color {
    display: inline-block;
    width: 60px;
}

.section-inline-color input {
    width: 60px !important;
    text-align: center;
}

.section-inline-color span {
    width: 65px;
}
`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/html-graph/graph-property/graph-property.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".graph-selection {\n    position: absolute;\n    top: 35px;\n    bottom: 0px;\n    overflow: auto;\n    width: 340px;\n}\n\n.section-item {\n    width: 100%;\n}\n\n.section-item-block mat-select, span {\n    width:calc(100% - 15px);\n}\n\n.section-inline-number {\n    display: inline-block;\n    width: 60px;\n}\n\n.section-inline-number input {\n    width: inherit;\n    text-align: center;\n}\n\n.section-inline-number span {\n    width: 65px;\n    text-align: left;\n}\n\n.section-inline-toggle {\n    display: inline-block;\n    width: 50px;\n    text-align: center;\n}\n\n.section-inline-toggle span {\n    width: inherit;\n}\n\n.section-inline-toggle mat-slide-toggle {\n    width: inherit;\n    padding-left: 10px;\n}\n\n.section-inline-toggle-ext span {\n    width: 85px;\n}\n\n.section-inline-toggle-ext mat-slide-toggle {\n    padding-left: 20px;\n}\n\n.section-inline-color {\n    display: inline-block;\n    width: 60px;\n}\n\n.section-inline-color input {\n    width: 60px !important;\n    text-align: center;\n}\n\n.section-inline-color span {\n    width: 65px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
