import { Component } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class IndexedDb {
  info = {};
  infoDefalut = {"version":"1.11","hmi":{"layout":{"autoresize":false,"start":"v_a3504a811-36120","navigation":{"bkcolor":"#F4F5F7","fgcolor":"#1D1D1D","logo":false,"mode":"over","type":"block"},"header":{"bkcolor":"#ffffff","fgcolor":"#000000","fontSize":13,"itemsAnchor":"left"},"showdev":true,"inputdialog":"false","hidenavigation":false,"theme":"","loginonstart":false,"loginoverlaycolor":"none","show_connection_error":true},"views":[{"id":"v_b102b4491-b141","name":"test","profile":{"width":1024,"height":768,"bkcolor":"#ffffffff","margin":10},"items":{},"variables":{},"svgcontent":"","type":"svg"}]},"devices":[],"charts":[],"graphs":[],"alarms":[],"notifications":[],"scripts":[],"reports":[],"texts":[],"plugin":[],"name":"noname"};


  constructor(private dbService: NgxIndexedDBService) {}
    getVersion() {
        this.dbService.getDatabaseVersion().pipe(
            tap(response => console.log('Versione database => ', response)),
            catchError(err => {
              console.error('Error recover version => ', err);
              return err;
            })
          ).subscribe();
    }

    getAllData(){
        this.dbService.getAll('data').subscribe(
            data => {
                this.info = data;
                console.log('alldata => ', data);
                
            },
            error => console.error('Error getting people', error)
          );
    }
    clearData(){
        this.dbService.clear('data').subscribe(
            () => console.log('data cleared'),
            error => console.error('Error clearing data', error)
        );
    }
    delDate(name:string){
        this.dbService.delete('data', name).subscribe(
            () => console.log('data deleted'),
            error => console.error('Error deleting data', error)
        );
    }

    getItem(name:string): Observable<any> {
      this.getAllData();
      // this.clearData();
      return new Observable((observer) => {
        this.dbService.getByIndex('data','name', name).subscribe(
              data => {
                  console.log('data  => ', data);
                  if(data == undefined || data == null){
                      this.info = null;
                      observer.next(this.infoDefalut);
                      observer.complete();
                  }else{
                    if(data["value"] === null){
                        data["value"] = JSON.stringify(this.infoDefalut)
                    }
                    this.info = data;
                    observer.next(JSON.parse(this.info['value']));//[]
                    console.log("getItem",name,data)
                    observer.complete();  
                  }
                  
              },
              error => {
                observer.error(error);
                observer.complete();
              }
          );
        
      });
    }
    // getItem(id:string){
        // var temp = await this.dbService.getByKey('data', 0).toPromise();
        // temp.forEach(element => {            
        //     if(element["name"] == id){
        //         return element["value"];
        //     }
        // });
        // console.log('getItem',id,temp);
        // if(temp!= null){
        //     return temp["value"];
        // }
        
        // this.dbService.clear('data').subscribe(
        //     () => console.log('data cleared'),
        //     error => console.error('Error clearing data', error)
        // );

        // await this.dbService.getByIndex('data','name', '123').subscribe(
        //     data => {
        //         console.log('data => ', data);
        //         return data;
        //     },
        //     error => {
        //         console.error('Error getting data', error)
        //         return null;}
        // );
        // return null;
        // .subscribe(
        //     data => {
        //         console.log('data => ', data);
        //         return data;
        //     },
        //     error => console.error('Error getting data', error)
        // )

        // this.dbService.getAll('data').subscribe(
        //     data => {
        //         this.info = data;
        //         console.log('alldata => ', data[0]);
                
        //     },
        //     error => console.error('Error getting people', error)
        //   );
        
    //     if(this.info.length > 0){
    //         return this.info[0]
    //     }
    //      return null; 
        
    // }

    setItem(name:string,value:string){
      console.log('setItem',name,value);
      return new Observable((observer) => {
        
        // this.dbService.deleteObjectStore('data');
        
        this.getItem(name).subscribe(
          data => {
            if((this.info == null)||(this.info == undefined)){
                this.dbService.add('data', { name: name, value: value}).subscribe(
                  () => {
                    console.log('data added');
                  },
                  error => console.error('Error adding person', error)
                );
            }else{
                this.dbService.update('data', {id:this.info['id'], name: name, value: value }).subscribe(
                  () => {
                    console.log('data updated');
                },
                  error => console.error('Error updating person', error)
                );
            }
            observer.next();
          }
          ,
          error => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );
    }
}
