import { Component, Injectable } from '@angular/core';
import {
	IMqttMessage,
	IMqttServiceOptions,
	MqttService,
	IPublishOptions,
	MqttConnectionState,
	
   } from 'ngx-mqtt';

  import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
  import { v4 as uuidv4 } from 'uuid';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User, UserGroups } from '../_models/user';
import { environment } from '../../environments/environment';
import { EndPointApi } from '../_helpers/endpointapi';
import {MediaInfo,RegAddrType,Slave } from '../_models/modinfo';
import { GaugesManager } from '../gauges/gauges.component';
import { HmiService, ScriptSetView } from '../_services/hmi.service';
import { Variable } from '../_models/hmi';
import { IndexedDb } from './datadb';
import { ProjectService } from './project.service';
import { error } from 'console';



@Injectable({
	providedIn: 'root'
  })


export class MqttClient {
    // 	export type MqttConnectedFunc = () => boolean ;
    // export type MqttPublishFunc = (topic: string, message: string | Buffer) => void ;
    // export type MqttSubscribeFunc = (topic: string) => void ;
        topicDatDown:string = "/user/up_raw/";
        topicSetDown:string = "/user/up_set/";
        topicStaDown:string = "/user/up_sta/";
    
        topicDatUp:string = "/user/down_raw/";//send  7CDFA10BE5A8
        topicSetUp:string = "/user/down_set/";
        topicStaUp:string = "/user/down_sta/";
		private mqttService: MqttService;
		private connected:boolean = false;
		private hmiService: HmiService;
		
    
        mediaInfo:MediaInfo;
    	listSlave:Slave[];
		private result: Variable[] = [];
		macInfoSendFlag:boolean = false;
		
        constructor(
			private _mqttService: MqttService,
            private element:Object,
			public gaugesManager: GaugesManager,
			private _hmiService: HmiService,
		
			
        ) {
            let macInfo = JSON.parse(element['macInfo']);
            let media = macInfo['media'];
            // console.log("macInfo",macInfo);
            // console.log("media",media);
			this.hmiService = _hmiService;
            this.mediaInfo = new MediaInfo(element["id"],element["clientid"],false,media['name']);
			this.hmiService = _hmiService;
			this.result = this.gaugesManager.getMappedGaugesSignals(true);
			
			
			this.topicDatDown = this.topicDatDown+element["clientid"]; 
            this.topicSetDown = this.topicSetDown+element["clientid"]; 
            this.topicStaDown = this.topicStaDown+element["clientid"]; 
    
            this.topicDatUp = this.topicDatUp+element["clientid"]; 
            this.topicSetUp = this.topicSetUp+element["clientid"]; 
            this.topicStaUp = this.topicStaUp+element["clientid"]; 
            console.log("topicSetUp",this.topicSetUp);
			this.mqttService = _mqttService;
			this.mqttService.state.subscribe(state =>{
				if(state === MqttConnectionState.CONNECTED){
					this.connected = true;
				}else{
					this.connected = false;
				}
				console.log('mqtt state',this.connected);
			});
        }
    
        timerDeal(){
        	if((this.connected)&&(this.mediaInfo.clientid !== '0')){
        		this.sendData({"cmd":"fresh"});
        		console.log('fresh');
        	}
        }
    
        publish(list: string | Buffer){
        	if(!this.connected)return;
        	this.mqttService.publish(this.topicSetUp,list,{ qos: 1, retain: false }).subscribe(val=>{});
        	console.log('publish',this.topicSetUp,list);
        }
    
        sendData(data:Object){
        	console.log('publish json',data,JSON.stringify(data),this.topicSetUp);
        	this.publish(JSON.stringify(data));
        }
    
        subscribe(){
        	// this.mqttService.observe(this.topicDatDown).subscribe(msg=>{
			// 	console.log(this.topicDatDown,msg,msg.topic,msg.payload.toString);
			// });
        	this.mqttService.observe(this.topicSetDown).subscribe(msg=>{
				this.parse(msg.payload);
			});
        	this.mqttService.observe(this.topicStaDown).subscribe(msg=>{
				this.parse(msg.payload);
			});
    
        	// if(!this.connected)return;
        	this.mqttService.publish(this.topicStaUp,'{}',{ qos: 1, retain: false }).subscribe(val=>{});
        	console.log('subscribe',this.topicStaUp,this.mediaInfo.clientid);
    
        }

		parse(Uint8Array:Uint8Array){
			const decoder = new TextDecoder('utf-8');
			const str = decoder.decode(Uint8Array);
			let json = JSON.parse(str);
			let cmd = json["cmd"];
			console.log("parse",json,json["cmd"]);
			if(cmd===undefined){
				if(json["sta"] !== undefined){
					console.log("state",json["sta"]);
					this.stateRev(json);
				}
				else return;
			}else if(cmd==='10mindat'){
        		this.dat10minRev(json["data"])
			}else if(cmd==='macinfo'){
				if(this.macInfoSendFlag){
					this.macInfoSendFlag = false;
					this.macInfoRev(json["data"]);
				}
			}else if(cmd==='slave'){
				let slave:Slave = this.addSlaveRev(json["data"]);
        		this.updateDataSend(slave);
			}else if(cmd==='sync'){
				this.syncRev(json["data"]);
			}else if(cmd==='i'){
				
			}
		}
	
		getMediaName(name:string){
			return '/'+this.mediaInfo.clientid+'/'+name;
		}

		// /clientid/1/val/1
		// /clientid/1/warn/1
		//  /864814071804149/1/ouput/val/1
		getSlaveName(addr:number,name:string){
			console.log("getSlaveName",'/'+this.mediaInfo.clientid+'/'+addr+'/'+name);
			return '/'+this.mediaInfo.clientid+'/'+addr+'/'+name;
		}
	
		stateRev(json:any){
			if(this.mediaInfo.state !== json["sta"]){
				this.mediaInfo.state = json["sta"];
				if(this.mediaInfo.state){
					this.macInfoSendFlag = true;
					this.listSlave = [];
					this.freshUiCompent(this.getMediaName('state'),true)
					this.sendData({"cmd":"macinfo"});
				}else{
					this.freshUiCompent(this.getMediaName('state'),false)
				}
			}
		}

		macInfoRev(json:any){
			this.mediaInfo.parseJson(json["media"]);
			this.freshUiCompent(this.getMediaName('name'),this.mediaInfo.rssi);
			this.freshUiCompent(this.getMediaName('rssi'),this.mediaInfo.rssi);
			this.sendData({"cmd":"slave","index":0});//
		}

		dat10minRev(json:any){
			this.mediaInfo.rssi = json["rssi"]??0;
			this.freshUiCompent(this.getMediaName('rssi'),this.mediaInfo.rssi)
		}

		addSlaveRev(json:any){
			let flag:Boolean = false;
			let slave:Slave = this.getSlave(json["addr"]);
			if(slave){

			}else{
				slave = new Slave();
				slave.parseJson(json);
				this.listSlave.push(slave);
			}
			return slave;
		}

		updateDataSend(slave:Slave){//
			this.sendData({"cmd":"freshUpdate","data":{"addr":slave.addr}});
		}

		getSlave(addr:number){
			return this.listSlave.find(x=>x.addr===addr);
		}

		syncRev(json:any){
			console.log("syncRev1",typeof(json));
			for(let key in json){
				let addr: number = parseInt(key, 10);
				if (!isNaN(addr)) {
					console.log("syncRev",key,typeof(key));
					let slave:Slave = this.getSlave(addr);
					if(slave){
						let value = json[key];
						let listinfo = value["info"];
						slave.state = value["sta"]>0;
						slave.warn = value["warn"];

						console.log("syncRev value",value);

						if((listinfo!==undefined)&&(listinfo.len>17)){
							slave.ver = listinfo[0];
						}

						let listRegAddrSpace = slave.listRegAddrSpace;
						for (let index = 0; index < listRegAddrSpace.length; index++) {
							const element = listRegAddrSpace[index];
							let ty:string = element.regAddrType.toString();
							let t = value[ty];
							let listdata = t["data"];
                			let listwarn = t["warn"];
							console.log("syncRev listdata",listdata,element.listVal.length);
							
							for (var k = 0; k < element.listVal.length; k++) {
								let dat:number =(listdata[0]>>k)&0x01;
								console.log("data",dat,element.listVal.length);
								if(dat !== element.listVal[k]){
									element.listVal[k] = dat;
									if(element.regAddrType == RegAddrType.bitInput){
										this.freshUiCompent(this.getSlaveName(addr,'input/val/'+(k+1)),element.listVal[k])
									}else if(element.regAddrType == RegAddrType.bitOutput){
										this.freshUiCompent(this.getSlaveName(addr,'ouput/val/'+(k+1)),element.listVal[k])
									}else if(element.regAddrType == RegAddrType.dataSensor){
										this.freshUiCompent(this.getSlaveName(addr,'num/val/'+(k+1)),element.listVal[k])
									}
								}
							}
							for (var j = 0; j < element.listWarn.length; j++) {
								let warn:number = (listwarn[0]>>j)&0x01;
								if(warn !== element.listWarn[j]){
									element.listWarn[j] = warn;
									if(element.regAddrType == RegAddrType.bitInput){
										this.freshUiCompent(this.getSlaveName(addr,'input/warn/'+(k+1)),element.listVal[k])
									}else if(element.regAddrType == RegAddrType.bitOutput){
										this.freshUiCompent(this.getSlaveName(addr,'ouput/warn/'+(k+1)),element.listVal[k])
									}else if(element.regAddrType == RegAddrType.dataSensor){
										this.freshUiCompent(this.getSlaveName(addr,'num/warn/'+(k+1)),element.listVal[k])
									}
								}
							}
						}
						
					}
				}
			}
			
		}

		getUiid(name:string){
			let res: Variable;
			for (let index = 0; index < this.result.length; index++) {
				if(name === this.result[index].name){
					res = this.result[index];
					break;
				} 
			}
			return res;
		}
	
		freshUiCompent(name:string,val:any){
			let res: Variable = this.getUiid(name);
			console.log('onOkClick success', res); 
			if(res !== undefined){
				res.value = val;
				this.hmiService.setSignalValue(res);    
			}
		   
		}
    
    }




@Injectable()
export class MqttClientCustomService {

	private currentUser: UserProfile;
	private endPointConfig: string = EndPointApi.getURL();
	currentUser$ = new BehaviorSubject<UserProfile>(null);
	private uuid: string;
	private mqttService: MqttService;
	private curSubscription: Subscription | undefined;
	connection = {
		hostname: environment.mqttServer,
		port: environment.mqttPort,
		path: '/mqtt',
		clean: true, // Retain session
		connectTimeout: 4000, // Timeout period
		reconnectPeriod: 4000, // Reconnect period
		// Authentication information
		clientId: 'mqttx_597046f4',
		username: 'emqx_test',
		password: 'emqx_test',
		protocol: 'ws',
	}
	subscription = {
	topic: 'topic/mqttx',
	qos: 0,
	};

	connected:boolean = false;


	listMqttClient:MqttClient[];
	private hmiService: HmiService;
	indexedDB: IndexedDb;
	constructor(
		private http: HttpClient,
		private _mqttService: MqttService,
		public gaugesManager: GaugesManager,
		private _hmiService: HmiService,
		indexedDB: IndexedDb,
		private projectService: ProjectService
		) {
		let user = JSON.parse(localStorage.getItem('currentUser'));
		if (user) {
		  this.currentUser = user;
		}
		this.currentUser$.next(this.currentUser);

		this.hmiService = _hmiService;
		this.mqttService = _mqttService;
		this.indexedDB = indexedDB;

		let userInfoStor = localStorage.getItem('userInfoStor');
		if(!userInfoStor){
			localStorage.setItem('userInfoStor','');
		}
		
		
	}
	generateUniqueId(): string {
		return uuidv4();
	  }

	connectMqttServer(username: string, password: string):Observable<any> {
		return new Observable((observer) => {
			this.uuid = this.generateUniqueId();
			this.connection.clientId = 'W'+this.uuid;
			this.connection.username = username;
			this.connection.password = password;
			try {
				this.mqttService.connect(this.connection as IMqttServiceOptions)
			} catch (error) {
				console.log('mqtt.connect error', error);
			}
			this.mqttService.onConnect.subscribe(() => {
				let user = new UserProfile();
				user.username = username;
				user.password = password;
				this.currentUser = user;
				this.currentUser$.next(this.currentUser);
				this.saveUserToken(user);
				console.log('mqtt.onConnect');
				// this.mqttService.observe('').subscribe((m:IMqttMessage)=>{
				// 	console.log('s',m.payload);
				// });

				observer.next(true);
				observer.complete();

				this.getClient(username);
			});

			this.mqttService.onOffline.subscribe(() => {
				console.log('mqtt.onOffline');
			});

			this.mqttService.onClose.subscribe(() => {
				console.log('mqtt.onClose');
			});
			this.mqttService.onEnd.subscribe(() => {
				console.log('mqtt.onEnd');
			});
			this.mqttService.onError.subscribe((error) => {
				console.log('mqtt.onError', error);
				this.mqttService.disconnect();
				observer.error(error);
				
			});
			// this.mqttService.publish.subscribe((error) => {
			// 	console.log('mqtt.onPacketsend', error);

			// });
			// this.mqttService.onMessage.subscribe((message: IMqttMessage) => {
			// 	console.log('mqtt.onMessage', message.payload.toString());
			// });
		 
		});
		
		
	}

	// publishMessage(topic: string, message: string | Buffer){
	// 	this.mqttService.publish(topic,message);
		
	// }

	// subscribe(topic: string){
	// 	this.mqttService.observe(topic);
	// 	// .subscribe((m:IMqttMessage)=>{
	// 	// 	console.log('s',m.payload);
	// 	// });
	// }


	private getClient(userName:string){
		let header = new HttpHeaders({ 'Content-Type': 'application/json' });
		let params = {username: userName};
		console.log("getClient",userName,environment.domainBg);

		this.http.get(environment.domainBg + '/user/queryConfigurationByUsername', { headers: header, params: params, responseType: 'json' }).subscribe((result: any) => {
			console.log("queryConfigurationByUsername",result);
			let dat = result['data'];
			this.indexedDB.setItem("123", dat['configuration']).subscribe(()=>{
				console.log("queryConfigurationByUsername..1");
			},
		error=>{

		})
			// this.projectService.onRefreshProject();
			
		});

		this.http.get(environment.domainBg + '/deviceInfo/selectClientid', { headers: header, params: params, responseType: 'json' }).subscribe((result: any) => {
			if (result) {
				
				if(result.errorCode === '200'){
					let dat = result['data'];
					let clientAll = dat['deviceInfoClientIds'];
					this.listMqttClient=[];
					for (let index = 0; index < clientAll.length; index++) {
						let element = clientAll[index];
						console.log("selectClientid result",element);
						let mqttClient:MqttClient = new MqttClient(this.mqttService,element,this.gaugesManager,this.hmiService);

						this.listMqttClient.push(mqttClient);
						mqttClient.subscribe();
						
						console.log("selectClientid len",this.listMqttClient.length);
					}
				}
				// this.currentUser = <UserProfile>result.data;
				// this.saveUserToken(this.currentUser);
				// this.currentUser$.next(this.currentUser);
			}
			// observer.next();
		}, err => {
			console.error(err);
			// observer.error(err);
		});
	}

	// private aaa(){
	// 	for (let index = 0; index < this.listMasterDeviceInfo.length; index++) {
	// 		const element = this.listMasterDeviceInfo[index];
			
	// 	}
		
	// }

	

	



	// signIn(username: string, password: string) {
	// 	return new Observable((observer) => {
	// 		if (environment.serverEnabled) {
	// 			let header = new HttpHeaders({ 'Content-Type': 'application/json' });
	// 			return this.http.post(this.endPointConfig + '/api/signin', { username: username, password: password }).subscribe((result: any) => {
	// 				if (result) {
	// 					this.currentUser = <UserProfile>result.data;
	// 					this.saveUserToken(this.currentUser);
	// 					this.currentUser$.next(this.currentUser);
	// 				}
	// 				observer.next();
	// 			}, err => {
	// 				console.error(err);
	// 				observer.error(err);
	// 			});
	// 		} else {
	// 			observer.next();
	// 		}
	// 	});

	// }

	// signOut() {
	// 	this.removeUser();
	// }

	getUser(): User {
		return this.currentUser;
	}

	getUserProfile(): UserProfile {
		return this.currentUser;
	}

	// getUserToken(): string {
	// 	return this.currentUser?.token;
	// }

    // isAdmin(): boolean {
    //     if (this.currentUser && UserGroups.ADMINMASK.indexOf(this.currentUser.groups) !== -1) {
    //         return true;
    //     }
    //     return false;
    // }

	setNewToken(token: string) {
		this.currentUser.token = token;
		this.saveUserToken(this.currentUser);
	}

	// to check by page refresh
	private saveUserToken(user: UserProfile) {
		localStorage.setItem('currentUser', JSON.stringify(user));
	}

	private removeUser() {
		this.currentUser = null;
		localStorage.removeItem('currentUser');
		this.currentUser$.next(this.currentUser);
	}
}

export class UserProfile extends User {
	token: string;
}


