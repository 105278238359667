// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-selection {
  position: absolute;
  top: 35px;
  bottom: 0px;
  overflow: auto;
  width: 340px;
}

.section-item {
  width: 100%;
}

.field-block {
  width: 100%;
}
.field-block mat-select, .field-block input, .field-block span {
  width: calc(100% - 15px);
}

.field-text input {
  width: 70px;
}

.field-number {
  width: 60px;
}

.field-number input {
  width: inherit;
  text-align: center;
}

.field-number span {
  width: 65px;
  text-align: left;
}

.section-inline-margin-s {
  display: inline-block;
  width: 5px;
}

.section-inline-color {
  display: inline-block;
  width: 70px;
}

.section-inline-color input {
  width: 70px !important;
  text-align: center;
}

.section-inline-color span {
  width: 70px;
}

.section-inline-margin {
  display: inline-block;
  width: 12px;
}

.section-inline-margin2 {
  display: inline-block;
  width: 16px;
}

.section-newline {
  margin-top: 5px;
}

::ng-deep .slider-field mat-slider {
  background-color: var(--formSliderBackground);
  height: 30px;
  width: 100px;
}

::ng-deep .mat-tab-label {
  height: 34px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/gauges/controls/html-chart/chart-property/chart-property.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AAAI;EACI,wBAAA;AAER;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;AACJ;;AAEA;EACI,qBAAA;EACA,UAAA;AACJ;;AAEA;EACI,qBAAA;EACA,WAAA;AACJ;;AAEA;EACI,sBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,qBAAA;EACA,WAAA;AACJ;;AAEA;EACI,qBAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,6CAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".chart-selection {\n    position: absolute;\n    top: 35px;\n    bottom: 0px;\n    overflow: auto;\n    width: 340px;\n}\n\n.section-item {\n    width: 100%;\n}\n\n.field-block {\n    width: 100%;\n    mat-select, input, span {\n        width:calc(100% - 15px);\n    }\n}\n\n.field-text input {\n    width: 70px;\n}\n\n.field-number {\n    width: 60px;\n}\n\n.field-number input {\n    width: inherit;\n    text-align: center;\n}\n\n.field-number span {\n    width: 65px;\n    text-align: left;\n}\n\n.section-inline-margin-s {\n    display: inline-block;\n    width: 5px;\n}\n\n.section-inline-color {\n    display: inline-block;\n    width: 70px;\n}\n\n.section-inline-color input {\n    width: 70px !important;\n    text-align: center;\n}\n\n.section-inline-color span {\n    width: 70px;\n}\n\n.section-inline-margin {\n    display: inline-block;\n    width: 12px;\n}\n\n.section-inline-margin2 {\n    display: inline-block;\n    width: 16px;\n}\n\n.section-newline {\n    margin-top: 5px;\n}\n\n::ng-deep .slider-field mat-slider {\n    background-color: var(--formSliderBackground);\n    height: 30px;\n    width: 100px;\n}\n\n::ng-deep .mat-tab-label {\n    height: 34px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
